import { ref } from 'vue';
import axios from '@/services/useAxios';

export default function () {
    const examenTemplate = ref(null);
    const examenTemplates = ref([]);

    const getExamenTemplate = async (id) => {
        const res = await axios.get(`/care/examen/examen-template/${id}`);

        if (res.status === 200) {
            examenTemplate.value = res.data;
        }
    };

    const getExamenTemplateByExamen = async (examenId) => {
        const data = [
            {
                field: 'examen',
                value: examenId,
                condition: 'and',
                equal: 'is',
            },
        ];
        const jsonString = JSON.stringify(data);
        const filters = encodeURIComponent(jsonString);

        const query = `?filters=${filters}`;

        const res = await axios.get(`/care/examen/examen-template${query}`);

        if (res.status === 200) {
            return res.data.items;
        }
        return [];
    };

    const getExamenTemplates = async (searchQuery = '') => {
        const query = searchQuery ? `?searchQuery=${searchQuery}` : '';

        const res = await axios.get(`/care/examen/examen-template${query}`);

        if (res.status === 200) {
            examenTemplates.value = res.data.items;
        }
    };

    const createExamenTemplate = async (data) => {
        const res = await axios.post('/care/examen/examen-template', data);
        if (res.status === 201) {
            // console.log(res.data);
            examenTemplate.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const updateExamenTemplate = async (id, data) => {
        const res = await axios.put(`/care/examen/examen-template/${id}`, data);
        if (res.status === 200) {
            // console.log(res.data);
            examenTemplate.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    return {
        examenTemplate,
        getExamenTemplate,
        getExamenTemplateByExamen,
        examenTemplates,
        getExamenTemplates,
        createExamenTemplate,
        updateExamenTemplate,
    };
}
