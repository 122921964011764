<template>
    <div class="darmin card">
        <div
            class="card-header pb-0 d-flex align-items-center justify-content-between"
            data-bs-toggle="collapse"
            :data-bs-target="`#card-${ramId}`"
            aria-expanded="true"
            :aria-controls="`card-${ramId}`"
        >
            <h5>Campos del Documento</h5>
            <i class="fas fa-angle-up"></i>
        </div>
        <div class="card-body theme-form">
            <div class="collapse show" :id="`card-${ramId}`">
                <h6>Agregar campo</h6>
                <div class="row">
                    <div class="col-lg-4">
                        <label class="col-form-label">Nombre</label>
                        <input class="form-control" type="text" v-model="newItem.name" />
                    </div>
                    <div class="col-lg-3 mb-3">
                        <label class="col-form-label">Tipo</label>
                        <select class="form-select digits" v-model="newItem.type">
                            <option value="String">Campo de texto</option>
                            <option value="Number">Numero</option>
                            <option value="marca_x">Marca con X</option>
                            <option value="Date">Fecha</option>
                            <option value="Age">Edad</option>
                            <option value="Paragrahp">Parrafo</option>
                            <option value="Paragrahp_optional">Parrafo opcional</option>
                            <option value="List">Lista</option>
                            <option value="Object">Object</option>
                        </select>
                    </div>
                    <div class="col-lg-3 mb-3">
                        <label class="col-form-label">Automatico</label>
                        <select class="form-select digits" v-model="newItem.auto">
                            <option value="true">Si</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div class="col-lg-3 mb-3">
                        <label class="col-form-label">Componente</label>
                        <input class="form-control" type="text" v-model="newItem.component" />
                    </div>
                    <div class="col-lg-2 d-flex align-items-end justify-content-end mb-3">
                        <button class="btn btn-primary" @click="handleAddItem">Agregar</button>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-12">
                        <div class="row mb-3 border-bottom g-0" v-for="(d, i) in data" :key="i">
                            <div class="col-md-3">
                                <h4>{{ d.name }}</h4>
                                <span v-if="d.type == 'String'"
                                    >Campo de texto {{ d.auto == 'true' ? 'Automatico' : '' }}</span
                                >
                                <span v-if="d.type == 'Number'">Numero</span>
                                <span v-if="d.type == 'marca_x'">Marca con X</span>
                                <span v-if="d.type == 'Date'">Fecha</span>
                                <span v-if="d.type == 'Age'">Edad</span>
                                <span v-if="d.type == 'Paragrahp'">Parrafo</span>
                                <span v-if="d.type == 'Paragrahp_optional'">Parrafo opcional</span>
                                <span v-if="d.type == 'List'">Lista</span>
                                <span v-if="d.type == 'Object'">Object</span>
                                <div class="d-flex align-items-center">
                                    <span class="me-1">Auto</span>
                                    <input type="checkbox" v-model="d.auto" />
                                </div>
                            </div>
                            <div class="col-md-7 d-flex align-items-center mb-3">
                                <div class="w-100">
                                    <label v-if="d.type == 'List'">Iterador</label>
                                    <input
                                        v-if="d.type == 'List'"
                                        class="form-control"
                                        type="text"
                                        v-model="d.listField"
                                        placeholder="list child"
                                    />
                                    <label v-if="d.auto !== 'true'">Default</label>
                                    <label v-else>Field</label>
                                    <input
                                        v-if="d.type == 'String' && d.auto !== 'true'"
                                        class="form-control"
                                        type="text"
                                        v-model="d.default"
                                    />
                                    <input
                                        v-if="d.type == 'String' && d.auto === 'true'"
                                        class="form-control"
                                        type="text"
                                        v-model="d.field"
                                    />
                                    <input
                                        v-if="d.type == 'Number'"
                                        class="form-control"
                                        type="number"
                                        v-model="d.default"
                                    />
                                    <select
                                        v-if="d.type == 'marca_x'"
                                        class="form-select digits"
                                        v-model="d.default"
                                    >
                                        <option value="true">Seleccionado</option>
                                        <option value="false">Vacio</option>
                                    </select>
                                    <input
                                        v-if="d.type == 'Date'"
                                        class="form-control"
                                        type="text"
                                        v-model="d.default"
                                    />
                                    <input
                                        v-if="d.type == 'Age'"
                                        class="form-control"
                                        type="text"
                                        v-model="d.default"
                                    />
                                    <textarea
                                        v-if="d.type == 'Paragrahp'"
                                        class="form-control"
                                        v-model="d.default"
                                        rows="4"
                                    ></textarea>
                                    <textarea
                                        v-if="d.type == 'Paragrahp_optional'"
                                        class="form-control"
                                        v-model="d.default"
                                        rows="4"
                                    ></textarea>
                                    <textarea
                                        v-if="d.type == 'List'"
                                        class="form-control"
                                        v-model="d.default"
                                        rows="5 "
                                    ></textarea>
                                    <textarea
                                        v-if="d.type == 'Object'"
                                        class="form-control"
                                        v-model="d.default"
                                        rows="5 "
                                    ></textarea>
                                </div>
                            </div>
                            <div class="col-lg-2 d-flex align-items-end justify-content-end mb-3">
                                <button class="btn btn-warning" @click="handleRemoveItem(i)">
                                    <i class="far fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <pre v-if="env === 'development'">{{ data }}</pre>
</template>

<script>
import { computed, ref, reactive } from 'vue';

export default {
    name: 'DocumentDataManager',
    props: {
        modelValue: Array,
    },
    setup(props, { emit }) {
        const ramId = ref(Math.floor(Math.random() * 1000));
        const newItem = reactive({
            name: '',
            type: '',
        });

        const data = computed({
            get() {
                return props.modelValue;
            },
            set(value) {
                emit('update:modelValue', value);
            },
        });

        const handleAddItem = () => {
            const { name, type } = newItem;
            data.value.push({ name, type });
            newItem.name = '';
            newItem.type = '';
        };

        const handleRemoveItem = (index) => {
            data.value = data.value.filter((v, i) => index !== i);
        };

        return {
            data,
            ramId,
            newItem,
            handleAddItem,
            handleRemoveItem,
            env: process.env.NODE_ENV,
        };
    },
};
</script>

<style></style>
